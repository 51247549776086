const sharedConfig: any = {
    APP_MODE: 'prod',

    VERSION: 'PROD-1',

    SERVER_ROOT_URL: "https://www.labie.io",
    API_ROOT_URL: "https://www.labie.io",

    //INTERNAL_API_ROOT_URL: "http://10.66.1.48:8002",
    INTERNAL_API_ROOT_URL: "https://www.labie.io",

    isDev: () => {
        return true;
    }
};

export default sharedConfig;
